import React from "react";
import { withStyles } from "@material-ui/core/styles";
import HomeImage from "../../images/home.png";
import {
  Typography,
  Button,
  withWidth,
  useMediaQuery,
} from "@material-ui/core";
import LinkWrapper from "../../components/LinkWrapper";

const styles = theme => ({
  root: {
    width: "100%",
    height: "100vh",
    flexGrow: 1,
    spacing: 0,
    position: "relative",
    justify: "space-around",
    textAlign: "center",
    overflow: "hidden",
  },
  background: {
    width: "100%",
    height: "100%",
    backgroundImage: `url(${HomeImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    backgroundAttachment: "fixed",
    "@supports (-webkit-touch-callout: none)": {
      backgroundAttachment: "scroll",
    },
    transform: "translateZ(-10px) scale(1.1)",
  },
  insideStyles: {
    color: "white",
    fontFamily: "Abril Fatface",
    fontWeight: "bold",
    lineHeight: "2em",
  },
  backdrop: {
    position: "absolute",
    height: "100%",
    width: "100%",
    backgroundColor: "rgb(66,73,125,0.28)",
  },
  title: {
    position: "absolute",
    bottom: "8%",
    width: "100%",
  },
  exploreBtn: {
    marginTop: "1.5em",
    [theme.breakpoints.down("md")]: {
      marginBottom: "8%",
    },
  },
  divider: {
    width: "55%",
    color: "white",
    backgroundColor: "white",
    height: 1.3,
    borderRadius: "1.8px",
  },
});
const ColorButton = withStyles(theme => ({
  root: {
    padding: "10px 40px",
    color: "white",
    border: "1.8px solid white",
    borderRadius: "32px",
    "&:hover": {
      color: "#fafafaAA",
      border: "1.5px solid #fafafaAA",
    },
  },
}))(Button);

const Home = props => {
  const isPhoneScreen = useMediaQuery("(max-width:600px)");
  var title = (
    <Typography variant="h3" className={props.classes.insideStyles}>
      GUIDING ● ENCOURAGING ● SUPPORTING
    </Typography>
  );
  if (isPhoneScreen) {
    title = (
      <div>
        <Typography variant="h3" className={props.classes.insideStyles}>
          GUIDING
        </Typography>
        <Typography variant="h3" className={props.classes.insideStyles}>
          ENCOURAGING
        </Typography>
        <Typography variant="h3" className={props.classes.insideStyles}>
          SUPPORTING
        </Typography>
      </div>
    );
  }
  return (
    <section id="Home" className={props.classes.root}>
      <div className={props.classes.background}>
        <div className={props.classes.backdrop}>
          <div className={props.classes.title}>
            {title}
            <hr className={props.classes.divider} />
            <Typography variant="h5" className={props.classes.insideStyles}>
              JOURNEYING WITH YOU
            </Typography>
            <LinkWrapper to="/#OurService">
              <ColorButton
                variant="outlined"
                size="medium"
                className={props.classes.exploreBtn}
              >
                Explore
              </ColorButton>
            </LinkWrapper>
          </div>
        </div>
      </div>
    </section>
  );
};

export default withStyles(styles)(Home);
