import React from "react";
import "../styles/index.css";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Home from "../sections/main/home";
import OurService from "../sections/main/our-service";
import OurFocus from "../sections/main/our-focus";
import Workshop from "../sections/main/workshop";
import AboutUs from "../sections/main/about-us";
import ContactUs from "../sections/main/contact-us";
import Testimonies from "../sections/main/testimonies";

const IndexPage = () => (
  <Layout>
    <SEO title="Cherng's Listening Ear" />
    <Home />
    <OurService />
    <OurFocus />
    <Workshop />
    <AboutUs />
    <Testimonies />
    <ContactUs />
  </Layout>
);

export default IndexPage;
