import React from "react";
import { withStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  Grid,
  Container,
  Typography,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
import ProfileImage from "../../images/profile.jpg";
import QualificationIcon from "../../components/assets/qualification-icon";
import CertificationIcon from "../../components/assets/certification-icon";

const styles = theme => ({
  root: {
    width: "100vw",
    height: "auto",
    flexGrow: 1,
    spacing: 0,
    justify: "space-around",
    textAlign: "center",
    alignItems: "center",
    paddingBottom: theme.spacing(5),
    paddingTop: theme.spacing(5),
  },
  container: {
    width: "80%",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
    padding: theme.spacing(2),
  },
  title: {
    fontWeight: "bold",
    color: "#181A34",
    marginBottom: "1%",
    textAlign: "left",
  },
  profileImage: {
    width: "100%",
    height: "100%",
    position: "relative",
    backgroundImage: `url(${ProfileImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center 20%",
    backgroundAttachment: "initial",
    borderRadius: "4px",
    [theme.breakpoints.down("sm")]: {
      height: "50vh",
      backgroundSize: "contain",
    },
  },
  content: {
    width: "100%",
    paddingTop: "2%",
    paddingBottom: "2%",
  },
  certificationContainer: {
    marginTop: "5%",
  },
  description: {
    color: "#80818D",
    textAlign: "left",
  },
  svgIcon: {
    color: "#4853BB",
    height: "40%",
    width: "40%",
    strokeWidth: 2,
  },
  listIcon: {
    color: "#4853BB",
    strokeWidth: 1,
    [theme.breakpoints.up("md")]: {
      paddingLeft: "10%",
    },
    [theme.breakpoints.up("xs")]: {
      paddingLeft: "3%",
    },
  },
});

const ColorButton = withStyles(theme => ({
  root: {
    padding: "10px 40px",
    color: "white",
    border: "1.8px solid white",
    borderRadius: "32px",
    fontWeight: "bold",
    "&:hover": {
      color: "#fafafaAA",
      border: "1.5px solid #fafafaAA",
    },
  },
}))(Button);
function useWidth() {
  const theme = useTheme();
  const keys = [...theme.breakpoints.keys].reverse();
  return (
    keys.reduce((output, key) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || "xs"
  );
}
const Workshop = props => {
  const width = useWidth()
  const classes = props.classes;
  return (
    <section id="AboutUs" className={classes.root}>
      <Container className={classes.container}>
        <Grid
          container
          spacing={width == "xs" ? 3 : 5}
          direction="row"
          justify="center"
          alignItems="stretch"
        >
          <Grid item xs={12} md={4}>
            <div className={classes.profileImage}></div>
          </Grid>
          <Grid item xs={12} md={8}>
            <div className={classes.content}>
              <Typography variant="h2" className={classes.title}>
                Chiang Ming Cherng
              </Typography>
              <Typography variant="h6" className={classes.description}>
                Ming Cherng started off his career working in hotels. After
                completing his first master degree, he taught in tertiary
                institutions and became the Senior Vice President of the
                Education and Assessment division at Shatec. His desire to
                better help students drove him to complete a master’s in
                counselling which enabled him to work closer with his students
                on their issues. Wanting to serve a wider group of people, Ming
                Cherng left teaching in 2010 to do counselling. He specializes
                in marital counselling and also works with people suffering from
                depression, addiction and other issues. He is writing a series
                of books to help couples improve their relationships and prepare
                for marriage. He is happily married to his God-chosen wife,
                Pauline How.
              </Typography>
            </div>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={3}
          direction="row"
          justify="center"
          alignItems="stretch"
          className={classes.certificationContainer}
        >
          <Grid item xs={12} md={4}>
            <Grid
              container
              direction="row"
              justify="center"
              spacing={0}
              alignItems="center"
            >
              <Grid item xs={3}>
                <QualificationIcon className={classes.svgIcon} />
              </Grid>
              <Grid item xs={9}>
                <Typography variant="h3" className={classes.title}>
                  Qualification
                </Typography>
              </Grid>
            </Grid>
            <List>
              <ListItem>
                <ListItemIcon className={classes.listIcon}>
                  <DoneIcon />
                </ListItemIcon>
                <ListItemText
                  className={classes.description}
                  primary="Master of Counselling, Monash University"
                />
              </ListItem>
              <ListItem>
                <ListItemIcon className={classes.listIcon}>
                  <DoneIcon />
                </ListItemIcon>
                <ListItemText
                  className={classes.description}
                  primary="Master of Applied Commerce (Organisational Change), The University of Melbourne"
                />
              </ListItem>
              <ListItem>
                <ListItemIcon className={classes.listIcon}>
                  <DoneIcon />
                </ListItemIcon>
                <ListItemText
                  className={classes.description}
                  primary="Bachelor of Science (HRTA) Magna cum Laude, University of Massachusetts Amherst"
                />
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid
              container
              direction="row"
              justify="center"
              spacing={0}
              alignItems="center"
            >
              <Grid item xs={3}>
                <QualificationIcon className={classes.svgIcon} />
              </Grid>
              <Grid item xs={9}>
                <Typography variant="h3" className={classes.title}>
                Counselling Styles and Practices
                </Typography>
              </Grid>
            </Grid>
            <List>
              <ListItem>
                <ListItemIcon className={classes.listIcon}>
                  <DoneIcon />
                </ListItemIcon>
                <ListItemText
                  className={classes.description}
                  primary="Narrative Ideas and Practices"
                />
              </ListItem>
              <ListItem>
                <ListItemIcon className={classes.listIcon}>
                  <DoneIcon />
                </ListItemIcon>
                <ListItemText
                  className={classes.description}
                  primary="EMDR"
                />
              </ListItem>
              <ListItem>
                <ListItemIcon className={classes.listIcon}>
                  <DoneIcon />
                </ListItemIcon>
                <ListItemText
                  className={classes.description}
                  primary="Theraplay"
                />
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid
              container
              direction="row"
              justify="center"
              spacing={0}
              alignItems="center"
            >
              <Grid item xs={3}>
                <CertificationIcon className={classes.svgIcon} />
              </Grid>
              <Grid item xs={9}>
                <Typography variant="h3" className={classes.title}>
                  Counselling Certifications
                </Typography>
              </Grid>
            </Grid>
            <List>
              <ListItem>
                <ListItemIcon className={classes.listIcon}>
                  <DoneIcon />
                </ListItemIcon>
                <ListItemText
                  className={classes.description}
                  primary="Certified and Accredited MBTI Administrator"
                />
              </ListItem>
              <ListItem>
                <ListItemIcon className={classes.listIcon}>
                  <DoneIcon />
                </ListItemIcon>
                <ListItemText
                  className={classes.description}
                  primary="Certified Prepare/Enrich Facilitator"
                />
              </ListItem>
              <ListItem>
                <ListItemIcon className={classes.listIcon}>
                  <DoneIcon />
                </ListItemIcon>
                <ListItemText
                  className={classes.description}
                  primary="Narrative Ideas and Practices (Level 2)"
                />
              </ListItem>
              <ListItem>
                <ListItemIcon className={classes.listIcon}>
                  <DoneIcon />
                </ListItemIcon>
                <ListItemText
                  className={classes.description}
                  primary="Certified Facilitator of LEGO®️ SERIOUS PLAY®️ method and materials"
                />
              </ListItem>
              <ListItem>
                <ListItemIcon className={classes.listIcon}>
                  <DoneIcon />
                </ListItemIcon>
                <ListItemText
                  className={classes.description}
                  primary="Brick-by-Brick Facilitator (Level 2)"
                />
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};

export default withStyles(styles)(Workshop);
