export const MainTestimonies = [
  {
    review:
      "I was truly blessed to be introduced to Ming Cherng after a few months of struggling with a seperation. I was able to rediscover myself after a long battle with depression. With his guidance, I was able to refocus myself. Throughout our journey together he has shown remarkable compassion. Even with his gentle approach towards addressing the issues surrounding my life, he was able to make me realize how important it is to hold on to discover and hold on to my identity. The methods and tools he uses are simple enough to apply on a daily basis to cope with daily struggles. I feel like a brand new person with a more positive outlook on life and I could not be more grateful for the opportunity to have him journey with me through a difficult time of my life. I encourage people from all walks of life to attend his workshops or to reach out for personal sessions.",
    name: "NH",
  },
  {
    review:
      "Suffering from depression, I sought help in every possible way I could.  By God’s grace, I was introduced by a Church friend to Ming Cherng for counselling.  I found Ming Cherng’s approach to be very effective.  Always patient in listening, he was able to guide me to reflect on the root cause of my depression.  What I liked was his collaborative approach in coming up with various alternatives or solutions to treat my depression.  I stopped taking anti depressant pills just after about a year of counselling and was free of depression after 2 years.  Today, I am glad that Ming Cherng and I have become good friends.",
    name: "FY",
  }
];
