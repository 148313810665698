import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Container, Typography, Button } from "@material-ui/core";
import MarriagePrepImage from "../../images/marriage-preparation.jpg";
import OtherWorkshopImage from "../../images/other-workshop.jpg";
import LinkWrapper from "../../components/LinkWrapper";

const styles = theme => ({
  root: {
    width: "100vw",
    height: "auto",
    flexGrow: 1,
    spacing: 0,
    justify: "space-around",
    textAlign: "center",
    marginTop: "35px",
    backgroundColor: "rgba(152, 216, 243, 0.1)",
    alignItems: "center",
    paddingBottom: theme.spacing(5),
    paddingTop: theme.spacing(5),
  },
  container: {
    width: "80%",
    [theme.breakpoints.down("sm")]: {
      width: "95%",
    },
    padding: theme.spacing(2),
  },
  title: {
    fontWeight: "bold",
    color: "#181A34",
    marginBottom: "5%",
  },
  imageLeft: {
    width: "100%",
    height: "70vh",
    position: "relative",
    textAlign: "center",
    backgroundImage: `url(${MarriagePrepImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    backgroundAttachment: "initial",
    borderRadius: "4px",
  },
  imageRight: {
    width: "100%",
    height: "70vh",
    position: "relative",
    textAlign: "center",
    backgroundImage: `url(${OtherWorkshopImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "100% 80%",
    backgroundAttachment: "initial",
    borderRadius: "4px",
  },
  backdrop: {
    position: "absolute",
    height: "100%",
    width: "100%",
    borderRadius: "4px",
    backgroundColor: "rgba(25, 27, 33, 0.5)",
  },
  description: {
    position: "absolute",
    bottom: "8%",
    width: "100%",
  },
  descriptionText: {
    fontWeight: "bold",
    color: "white",
    paddingLeft: "20%",
    paddingRight: "20%",
    paddingBottom: "3%",
  },
  imageContainer: {
    [theme.breakpoints.down("sm")]: {
      paddingTop: "10%",
    },
  },
});

const ColorButton = withStyles(theme => ({
  root: {
    padding: "10px 40px",
    color: "white",
    border: "1.8px solid white",
    borderRadius: "32px",
    fontWeight: "bold",
    "&:hover": {
      color: "#fafafaAA",
      border: "1.5px solid #fafafaAA",
    },
  },
}))(Button);

const Workshop = props => {
  const classes = props.classes;
  return (
    <section id="Workshop" className={classes.root}>
      <Container className={classes.container}>
        <Typography variant="h2" className={props.classes.title}>
          Workshops
        </Typography>
        <Grid
          container
          spacing={0}
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Grid item xs={12} md={6} className={classes.imageContainer}>
            <div className={classes.imageLeft}>
              <div className={classes.backdrop}>
                <div className={classes.description}>
                  <Typography variant="h2" className={classes.descriptionText}>
                    Marriage Preparation
                  </Typography>
                  <LinkWrapper to="/marriage-workshop">
                    <ColorButton variant="outlined" size="medium">
                      Explore
                    </ColorButton>
                  </LinkWrapper>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6} className={classes.imageContainer}>
            <div className={classes.imageRight}>
              <div className={classes.backdrop}>
                <div className={classes.description}>
                  <Typography variant="h2" className={classes.descriptionText}>
                    Others
                  </Typography>
                  <LinkWrapper to="/other-workshop">
                    <ColorButton variant="outlined" size="medium">
                      Explore
                    </ColorButton>
                  </LinkWrapper>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};

export default withStyles(styles)(Workshop);
