import React from "react";
import { withStyles } from "@material-ui/core/styles";
import RelationshipImage from "../../images/relationship.jpg";
import EmotionImage from "../../images/handling-emotion.jpg";
import IdentityImage from "../../images/understanding-identity.jpg";
import LivingImage from "../../images/living-life.jpg";
import {
  Typography,
  Grid,
  Card,
  Container,
  CardContent,
} from "@material-ui/core";

const styles = theme => ({
  root: {
    width: "100vw",
    height: "auto",
    flexGrow: 1,
    spacing: 0,
    justify: "space-around",
    textAlign: "center",
    marginTop: "35px",
    alignItems: "center",
  },
  container: {
    width: "80%",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
    padding: theme.spacing(2),
  },
  title: {
    fontWeight: "bold",
    color: "#181A34",
    marginBottom: "5%",
  },
  img: {
    width: "100%",
    height: "100%",
    top: "50%",
    transform: "translate(-50%,-50%)",
    position: "absolute",
    objectFit: "cover",
    objectPosition: "100% 15%",
  },
  imgContainer: {
    height: 160,
    position: "relative",
  },
  card: {
    height: "100%",
  },
});

const OurFocus = props => {
  return (
    <section id="OurFocus" className={props.classes.root}>
      <Container className={props.classes.container}>
        <Typography variant="h2" className={props.classes.title}>
          Our Focus
        </Typography>
        <Grid
          container
          spacing={5}
          direction="row"
          justify="center"
          alignItems="stretch"
        >
          <Grid item xs={12} md={3}>
            <Card className={props.classes.card}>
              <div className={props.classes.imgContainer}>
                <img src={RelationshipImage} className={props.classes.img} />
              </div>
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Strengthening Relationship
                </Typography>
                <Typography
                  gutterBottom
                  variant="body2"
                  color="textSecondary"
                  component="p"
                >
                  Friendship
                </Typography>
                <Typography
                  gutterBottom
                  variant="body2"
                  color="textSecondary"
                  component="p"
                >
                  Pre-Marital
                </Typography>
                <Typography
                  gutterBottom
                  variant="body2"
                  color="textSecondary"
                  component="p"
                >
                  Marital
                </Typography>
                <Typography
                  gutterBottom
                  variant="body2"
                  color="textSecondary"
                  component="p"
                >
                  Parent/Child
                </Typography>
                <Typography
                  gutterBottom
                  variant="body2"
                  color="textSecondary"
                  component="p"
                >
                  Workplace
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={3}>
            <Card className={props.classes.card}>
              <div className={props.classes.imgContainer}>
                <img src={EmotionImage} className={props.classes.img} />
              </div>
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Handling Emotions
                </Typography>
                <Typography
                  gutterBottom
                  variant="body2"
                  color="textSecondary"
                  component="p"
                >
                  Anxiety
                </Typography>
                <Typography
                  gutterBottom
                  variant="body2"
                  color="textSecondary"
                  component="p"
                >
                  Fear
                </Typography>
                <Typography
                  gutterBottom
                  variant="body2"
                  color="textSecondary"
                  component="p"
                >
                  Grief
                </Typography>
                <Typography
                  gutterBottom
                  variant="body2"
                  color="textSecondary"
                  component="p"
                >
                  Depression
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={3}>
            <Card className={props.classes.card}>
              <div className={props.classes.imgContainer}>
                <img src={IdentityImage} className={props.classes.img} />
              </div>
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Understanding Identity
                </Typography>
                <Typography
                  gutterBottom
                  variant="body2"
                  color="textSecondary"
                  component="p"
                >
                  Confidence
                </Typography>
                <Typography
                  gutterBottom
                  variant="body2"
                  color="textSecondary"
                  component="p"
                >
                  Personal Development
                </Typography>
                <Typography
                  gutterBottom
                  variant="body2"
                  color="textSecondary"
                  component="p"
                >
                  Sexuality
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={3}>
            <Card className={props.classes.card}>
              <div className={props.classes.imgContainer}>
                <img src={LivingImage} className={props.classes.img} />
              </div>
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Living Life
                </Typography>
                <Typography
                  gutterBottom
                  variant="body2"
                  color="textSecondary"
                  component="p"
                >
                  Worldviews
                </Typography>
                <Typography
                  gutterBottom
                  variant="body2"
                  color="textSecondary"
                  component="p"
                >
                  Spiritual Beliefs
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};

export default withStyles(styles)(OurFocus);
