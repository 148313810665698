import React from "react";
import { withStyles, useTheme } from "@material-ui/core/styles";
import {
  Grid,
  Container,
  Typography,
  Button,
  Slide,
  MobileStepper,
} from "@material-ui/core";
import CarouselCard from "../../components/CarouselCard";
import ArrowLeftIcon from "@material-ui/icons/ArrowBackIos";
import ArrowRightIcon from "@material-ui/icons/ArrowForwardIos";
import { MainTestimonies } from "../../components/assets/main-testimonies";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";

const styles = theme => ({
  root: {
    width: "100vw",
    height: "auto",
    flexGrow: 1,
    spacing: 0,
    justify: "space-around",
    textAlign: "center",
    marginTop: "35px",
    alignItems: "center",
    backgroundColor: "rgba(152, 216, 243, 0.1)",
    paddingBottom: theme.spacing(5),
    paddingTop: theme.spacing(5),
  },
  container: {
    width: "70%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    padding: theme.spacing(2),
  },
  title: {
    fontWeight: "bold",
    color: "#181A34",
    marginBottom: "3%",
    textAlign: "center",
  },
  description: {
    textAlign: "left",
  },
  reviewCard: {
    marginLeft: "1%",
    marginRight: "1%",
  },
  pagination: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
});

function Arrow(props) {
  const { direction, clickFunction } = props;
  const icon =
    direction === "left" ? (
      <ArrowLeftIcon style={{ color: "#98D8F38C" }} />
    ) : (
      <ArrowRightIcon style={{ color: "#98D8F38C" }} />
    );

  return <div onClick={clickFunction}>{icon}</div>;
}
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
const CustomMobileStepper = withStyles(theme => ({
  root: {
    "& .MuiMobileStepper-dotActive": {
      backgroundColor: "#98D8F3",
    },
  },
}))(MobileStepper);
const Testimonies = props => {
  const classes = props.classes;
  const theme = useTheme();
  const [index, setIndex] = React.useState(0);
  const numSlides = MainTestimonies.length;

  const onArrowClick = direction => {
    const increment = direction === "left" ? -1 : 1;
    const newIndex = (index + increment + numSlides) % numSlides;
    setIndex(newIndex);
  };

  const handleStepChange = index => {
    setIndex(index);
  };
  return (
    <section id="Testimonies" className={classes.root}>
      <Container className={classes.container}>
        <Typography variant="h2" className={classes.title}>
          What people say about us?
        </Typography>
        <Grid
          container
          spacing={0}
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Grid item xs={1}>
            <Arrow
              direction="left"
              clickFunction={() => onArrowClick("left")}
            />
          </Grid>
          <Grid item xs={10}>
            <AutoPlaySwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={index}
              onChangeIndex={handleStepChange}
              enableMouseEvents
              interval={20000}
            >
              {MainTestimonies.map((content, step) => (
                <div key={step} className={classes.reviewCard}>
                  <CarouselCard content={content} />
                </div>
              ))}
            </AutoPlaySwipeableViews>
          </Grid>
          <Grid item xs={1}>
            <Arrow
              direction="right"
              clickFunction={() => onArrowClick("right")}
            />
          </Grid>
        </Grid>
        <CustomMobileStepper
          variant="dots"
          steps={numSlides}
          position="static"
          activeStep={index}
          className={classes.pagination}
        />
      </Container>
    </section>
  );
};

export default withStyles(styles)(Testimonies);
