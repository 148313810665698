import React from "react";
import { withStyles } from "@material-ui/core/styles";
import SupportImage from "../../images/support.png";
import { Typography, Grid, Container } from "@material-ui/core";

const styles = theme => ({
  root: {
    width: "100vw",
    height: "auto",
    flexGrow: 1,
    spacing: 0,
    justify: "space-around",
    textAlign: "center",
    backgroundColor: "rgba(152, 216, 243, 0.1)",
    alignItems: "center",
  },
  container: {
    width: "80%",
    padding: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      marginTop: "10%",
      marginBottom: "10%",
    },
  },
  content: {
    marginTop: "10%",
    marginBottom: "10%",
    [theme.breakpoints.down("sm")]: {
      marginTop: "0%",
      marginBottom: "0%",
    },
  },
  media: {
    width: "100%",
    maxHeight: 320,
    objectFit: "cover",
    objectPosition: "100% 15%",
  },
  title: {
    textAlign: "left",
    fontWeight: "bold",
    color: "#181A34",
  },
  icon: {
    width: "auto",
    maxWidth: "60px",
    height: "auto",
    color: "#4853BB",
  },
  descriptionContainer: {
    paddingTop: "5%",
  },
  description: {
    color: "#80818D",
  },
});

const OurService = props => {
  return (
    <section id="OurService" className={props.classes.root}>
      <Container className={props.classes.container}>
        <Grid
          container
          spacing={5}
          direction="row"
          justify="center"
          alignItems="stretch"
        >
          <Grid item xs={12} md={6}>
            <div className={props.classes.content}>
              <img className={props.classes.media} src={SupportImage}></img>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid
              container
              spacing={5}
              direction="column"
              alignItems="flex-start"
              className={props.classes.content}
            >
              <Grid item>
                <Typography variant="h2" className={props.classes.title}>
                  What We Do
                </Typography>
              </Grid>
              <Grid item>
                <Grid
                  container
                  spacing={2}
                  direction="row"
                  alignItems="flex-start"
                >
                  <Grid item xs={2}>
                    <svg
                      id="nc_icon"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      viewBox="0 0 24 24"
                      data-type="glyph"
                      data-name="b-love"
                      className={props.classes.icon}
                    >
                      <path
                        fill="currentColor"
                        d="M9.884,19.499C9.023,18.815,7.563,18,5.5,18s-3.523,0.815-4.383,1.498C0.407,20.061,0,20.913,0,21.836V24h11 v-2.164C11,20.913,10.593,20.061,9.884,19.499z"
                      ></path>
                      <circle
                        fill="currentColor"
                        cx="5.5"
                        cy="13.5"
                        r="3.5"
                      ></circle>
                      <path
                        fill="currentColor"
                        d="M22.884,19.499C22.023,18.815,20.563,18,18.5,18s-3.523,0.815-4.383,1.498 C13.407,20.061,13,20.913,13,21.836V24h11v-2.164C24,20.913,23.593,20.061,22.884,19.499z"
                      ></path>{" "}
                      <circle
                        fill="currentColor"
                        cx="18.5"
                        cy="13.5"
                        r="3.5"
                      ></circle>
                      <path
                        data-color="color-2"
                        fill="currentColor"
                        d="M17,3.155c0-0.846-0.333-1.64-0.937-2.235V0.919c-0.678-0.669-1.612-1.004-2.565-0.902 C12.951,0.071,12.438,0.262,12,0.564c-0.438-0.303-0.951-0.493-1.498-0.548C9.547-0.084,8.614,0.251,7.937,0.92 C7.333,1.516,7,2.31,7,3.155s0.333,1.64,0.937,2.235L12,9.405l4.064-4.015C16.667,4.795,17,4.001,17,3.155z"
                      ></path>
                    </svg>
                  </Grid>
                  <Grid item xs={10}>
                    <Typography
                      variant="h6"
                      className={props.classes.description}
                      align="left"
                    >
                      We <b>listen,</b> seek to <b>understand</b> your situation
                      and <b>help</b> according to your needs
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid
                  container
                  spacing={2}
                  direction="row"
                  alignItems="flex-start"
                >
                  <Grid item xs={2}>
                    <svg
                      id="nc_icon"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      viewBox="0 0 24 24"
                      data-type="glyph"
                      data-name="family"
                      className={props.classes.icon}
                    >
                      <polygon
                        data-color="color-2"
                        fill="currentColor"
                        points="23.414,6.09 12,0.901 0.586,6.09 1.414,7.91 12,3.099 22.586,7.91 "
                      ></polygon>{" "}
                      <path
                        fill="currentColor"
                        d="M9.884,18.499C9.023,17.815,7.563,17,5.5,17s-3.523,0.815-4.383,1.498C0.407,19.061,0,19.912,0,20.836V23h11 v-2.164C11,19.912,10.593,19.061,9.884,18.499z"
                      ></path>{" "}
                      <circle
                        fill="currentColor"
                        cx="5.5"
                        cy="12.5"
                        r="3.5"
                      ></circle>{" "}
                      <path
                        fill="currentColor"
                        d="M22.884,18.499C22.023,17.815,20.563,17,18.5,17s-3.523,0.815-4.383,1.498 C13.407,19.061,13,19.912,13,20.836V23h11v-2.164C24,19.912,23.593,19.061,22.884,18.499z"
                      ></path>
                      <circle
                        fill="currentColor"
                        cx="18.5"
                        cy="12.5"
                        r="3.5"
                      ></circle>
                    </svg>
                  </Grid>
                  <Grid item xs={10}>
                    <Typography
                      variant="h6"
                      className={props.classes.description}
                      align="left"
                    >
                      We help by <b>guiding, encouraging, supporting</b> and{" "}
                      <b>journeying</b> with you to achieve your goals
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};

export default withStyles(styles)(OurService);
